// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-about-page-js": () => import("./../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-courses-page-js": () => import("./../src/templates/courses-page.js" /* webpackChunkName: "component---src-templates-courses-page-js" */),
  "component---src-templates-software-page-js": () => import("./../src/templates/software-page.js" /* webpackChunkName: "component---src-templates-software-page-js" */),
  "component---src-templates-webinar-post-js": () => import("./../src/templates/webinar-post.js" /* webpackChunkName: "component---src-templates-webinar-post-js" */),
  "component---src-templates-workflow-page-js": () => import("./../src/templates/workflow-page.js" /* webpackChunkName: "component---src-templates-workflow-page-js" */),
  "component---src-pages-blog-index-js": () => import("./../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-webinars-index-js": () => import("./../src/pages/webinars/index.js" /* webpackChunkName: "component---src-pages-webinars-index-js" */)
}

